<template>
  <div>
    <b-container fluid>
      <b-row>
        <b-col xs="6">
          <Highcharts :options="options" />
        </b-col>
        <b-col xs="6">
          <b-table :fields="['name', 'age', 'gender']"></b-table>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
export default {
  name: 'circlechart',
  data () {
    return {
      options: {
        chart: {
          type: 'pie'
        },
        series: [
          {
            data: [1, 2, 3]
          }
        ]
      }
    }
  }
}
</script>
